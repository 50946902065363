<template functional>
  <ul
    class="tw-text-blue-500 tw-m-0 tw-p-0 tw-px-2"
  >
    <li
      v-for="item in props.items"
      :key="item"
      class="tw-flex tw-items-center tw-mb-2 tw-text-left"
      data-test="item"
    >
      <ui-material-icon
        class="tw-text-sm tw-mr-2"
        name="check"
        data-test="icon"
      />
      <span
        v-text="item"
        data-test="content"
      />
    </li>
  </ul>
</template>

<script>
  /**
   * @module component - CheckedItemsList
   * @param {Array<string>} items
   */
  export default {
    name: 'CheckedItemsList',
    props: {
      items: {
        type: Array,
        required: true
      }
    }
  }
</script>
