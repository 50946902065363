<template>
  <div class="new-shipment-quotation-compare-dialog-contact tw-rounded tw-border-blue-500 tw-border tw-border-solid tw-flex tw-flex-col md:tw-flex-row">
    <div class="tw-bg-blue-500 tw-text-white md:tw-w-1/3 tw-flex tw-items-center tw-pl-4">
      <ui-ctk-icon
        class="tw-text-6xl"
        name="phone-help"
        data-test="icon"
      />
      <h2
        v-text="$t('shipments.titles.price_compare.question')"
        class="tw-text-xl tw-mb-0 tw-font-normal"
        data-test="title"
      />
    </div>
    <div class="md:tw-w-2/3 tw-bg-white tw-px-6 tw-py-3">
      <h3
        v-text="$t('shipments.titles.contact_us')"
        class="tw-text-blue-500 tw-text-xl tw-mb-0 tw-font-normal"
        data-test="subtitle"
      />
      <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-items-end">
        <p
          v-text="$t('shipments.paragraphs.price_compare.contact')"
          class="tw-mb-2 md:tw-mb-0 tw-flex-1 md:tw-mr-2"
          data-test="content"
        />
        <a
          v-text="$t('shipments.paragraphs.price_compare.contact_tel', {
            phone: phone.text
          })"
          :href="`tel:${phone.value}`"
          class="tw-text-blue-500 hover:tw-text-blue-600"
          data-test="phone"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import chronoinfos from '@/../config/chronoinfos'

  /**
   * @module component - NewShipmentQuotationCompareDialogContact
   */
  export default defineComponent({
    name: 'NewShipmentQuotationCompareDialogContact',
    data () {
      return {
        phone: chronoinfos.phones['pallet-network']
      }
    }
  })
</script>
