import axios from 'axios'
import * as Sentry from '@sentry/browser'

import { i18n } from '@/locales'
import Config from '@/services/Config'
import { isTesting } from '@/../utils/env'

import BaseAddressProvider from './BaseAddressProvider'

const getFormattedPrediction = ({
  objectID,
  postcode,
  country_code: country,
  city,
  locale_names: localeNames,
  _geoloc: location
}) => {
  const { locale } = i18n
  /**
   * Format the city & localeName with the user locale, if available.
   * If the locale is not available, then fallback to the default value.
   */
  const formattedCity = city &&
    ((city[locale] && city[locale][0]) || (city.default && city.default[0]))
  const formattedLocaleName = localeNames &&
    ((localeNames[locale] && localeNames[locale][0]) || (localeNames.default && localeNames.default[0]))

  return {
    id: objectID,
    city: formattedCity || formattedLocaleName,
    postalCode: postcode && postcode[0],
    location: {
      lat: location.lat || location[0].lat,
      lng: location.lng || location[0].lng
    },
    country: country.toUpperCase()
  }
}

export default class AlgoliaAddressProvider extends BaseAddressProvider {
  constructor (allowedCountries = []) {
    super(allowedCountries)

    this._axios = axios.create({
      baseURL: 'https://places-dsn.algolia.net/1/places',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Algolia-Application-Id': Config.get('algolia.appId'),
        'X-Algolia-API-Key': Config.get('algolia.id')
      }
    })
  }

  /**
   * Returns a list of predictions according to a query
   * @method getPredictions
   * @param {string} query
   * @returns {Promise<Array>} result
   */
  getPredictions (query) {
    return new Promise(resolve => {
      /**
       * If we are in the testing environment, ignore the Google autocomplete thing and
       * return a predefined list of addresses for tesing purposes; since we don't want
       * to use Google API during tests.
       */
      if (isTesting) {
        this.savedPredictions = [{
          id: 'PARIS',
          description: '75001 Paris',
          name: '75001 Paris',
          source: null,
          types: ['geocode'],
          provider: 'AlgoliaAddressProvider'
        }]
        resolve(this.savedPredictions)
        return false
      }

      this._axios.post('/query', {
        type: 'city',
        query,
        hitsPerPage: 10,
        countries: this.allowedCountries.map(country => country.toLowerCase()).join(',')
      })
        .then(response => {
          const formattedPredictions = response.data.hits
            .filter(({
              city,
              postcode,
              locale_names: localeNames,
              _geoloc: location
            }) => (city || localeNames) && (postcode && postcode[0]) && (location && !!location.lng && !!location.lat))
            .map(getFormattedPrediction)
            .map(({ id, city, postalCode }) => ({
              id,
              description: `${postalCode} ${city}`,
              name: city,
              types: ['geocode'],
              provider: 'AlgoliaAddressProvider'
            }))

          resolve(formattedPredictions)
        })
        .catch(err => {
          if (!err.response) return

          Sentry.captureException(err)

          resolve([])
        })
    })
  }

  /**
   * Returns a detailed address item
   * @method getItem
   * @param {string} id
   * @returns {Promise<AddressComponent|Error>} result
   */
  getItem (item) {
    return new Promise((resolve, reject) => {
      /**
       * If we are in the testing environment, ignore the Google autocomplete thing and
       * return a predefined list of addresses for tesing purposes; since we don't want
       * to use Google API during tests.
       */
      if (isTesting) {
        resolve({
          id: 'PARIS',
          city: 'Paris',
          postalCode: 75001,
          country: 'FR',
          location: {
            lat: 42,
            lng: 2
          }
        })
        return false
      }

      this._axios.get(`/${item.id}`)
        .then(response => {
          resolve(getFormattedPrediction(response.data))
        })
        .catch(err => {
          if (!err.response) return

          Sentry.captureException(err)

          reject(new Error('NOT_FOUND'))
        })
    })
  }
}
