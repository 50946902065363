<template>
  <div class="ctk-input-address-predictions tw-absolute tw-w-full">
    <ctk-input-select-list
      v-if="formatedPredictions.length"
      v-model="selectedPrediction"
      ref="select-list"
      class="ctk-input-address-predictions__list tw-w-full tw-m-0 tw-p-0"
      id="predictions"
      :items="formatedPredictions"
      @re-focus="refocus"
    >
      <template
        #item="{ item }"
      >
        <div class="tw-flex tw-w-full">
          <ui-ctk-icon
            v-if="hasMarker"
            :name="item.isSaved
              ? 'time'
              : item.isEstablishment
                ? 'company'
                : 'marker'"
          />
          <span class="dots-text">
            {{ item.text }}
          </span>
        </div>
      </template>
    </ctk-input-select-list>

    <!-- Case where there is no predictions to show.
      This also includes the case where there are no predictions but
      the help is shown.
    -->
    <ctk-input-address-predictions-empty
      v-if="!formatedPredictions.length && !$wait.is(`fetching predictions for ${$attrs.id}`) && !hasError"
    >
      <ctk-input-address-predictions-help
        v-if="hasHelp"
        :direction="direction"
        :content="$t('app.paragraphs.need_help_address')"
        class="tw-mt-3"
        v-matomo="{
          insert: { category: 'Quotations', action: 'Address Not Found Help Message' },
          click: { category: 'Quotations', action: 'Address Not Found Help Click', native: false },
        }"
      />
    </ctk-input-address-predictions-empty>

    <!-- This case is show when there is an error (per example the case where the address
      is not recognized). Show the empty state without the "no results" wording and a different
      help content.
    -->
    <ctk-input-address-predictions-empty
      v-if="hasError"
    >
      <template #no-result>
        <div />
      </template>

      <ctk-input-address-predictions-help
        :direction="direction"
        :content="$t('app.paragraphs.error.address_not_precise')"
        class="ctk-input-address-predictions-help__not-precise"
        v-matomo="{
          insert: { category: 'Quotations', action: 'Address Imprecise Help Message' },
          click: { category: 'Quotations', action: 'Address Imprecise Help Click', native: false }
        }"
      />
    </ctk-input-address-predictions-empty>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import CtkInputSelectList from '@/components/CtkInputs/CtkInputSelect/_subs/CtkInputSelectList/index.vue'
  import CtkInputAddressPredictionsEmpty from './_subs/CtkInputAddressPredictionsEmpty/index.vue'
  import CtkInputAddressPredictionsHelp from './_subs/CtkInputAddressPredictionsHelp/index.vue'

  /**
   * @module component - CtkInputAddressPredictions
   * @param {string} [direction=null]
   * @param {boolean} [hasMarker=true] - Set if the marker in the prediction items should be visible
   * @param {Array<any>} predictions
   */
  export default defineComponent({
    name: 'CtkInputAddressPredictions',
    components: {
      CtkInputSelectList,
      CtkInputAddressPredictionsEmpty,
      CtkInputAddressPredictionsHelp
    },
    props: {
      direction: {
        type: String,
        default: null
      },
      predictions: {
        type: Array,
        required: true
      },
      prediction: {
        type: [Object, String, Number],
        default: null
      },
      hasMarker: {
        type: Boolean,
        default: true
      },
      hasHelp: {
        type: Boolean,
        default: false
      },
      hasError: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      selectedPrediction: {
        get () {
          return this.prediction
        },
        set (v) {
          this.$emit('select', v)
        }
      },
      /**
       * Returns a list of predictions with custom formated data
       * @function formatedPredictions
       * @returns {Array<any>} predictions
       */
      formatedPredictions () {
        return this.predictions
          .map((/** @type {any} */prediction) => ({
            ...prediction,
            value: prediction.id,
            text: prediction.description,
            isEstablishment: prediction.types.includes('establishment'),
            isSaved: prediction.types.includes('saved')
          }))
          .slice(0, 10)
      }
    },
    methods: {
      focus () {
        /** @type {CtkInputSelectList} */
        const selectList = this.$refs['select-list']
        if (selectList) {
          selectList.setupFocus()
          selectList.focus()
        }
      },
      refocus () {
        this.$emit('re-focus')
      }
    }
  })
</script>

<style lang="scss">

  .ctk-input-address-predictions .ctk-input-select-list__item {
    border: none;
    padding: 0 10px;
    position: relative;
    height: 36px;
    line-height: 36px;
    cursor: pointer;
    text-align: left;
    background: transparent;

    &:not(:last-child) {
      border-bottom: 1px solid $divider;
    }
  }

  .ctk-input-address-predictions {
    &__list {
      border: 1px solid $divider;
    }
  }

</style>
