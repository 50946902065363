var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ctk-input-address-predictions tw-absolute tw-w-full"},[(_vm.formatedPredictions.length)?_c('ctk-input-select-list',{ref:"select-list",staticClass:"ctk-input-address-predictions__list tw-w-full tw-m-0 tw-p-0",attrs:{"id":"predictions","items":_vm.formatedPredictions},on:{"re-focus":_vm.refocus},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tw-flex tw-w-full"},[(_vm.hasMarker)?_c('ui-ctk-icon',{attrs:{"name":item.isSaved
            ? 'time'
            : item.isEstablishment
              ? 'company'
              : 'marker'}}):_vm._e(),_c('span',{staticClass:"dots-text"},[_vm._v(" "+_vm._s(item.text)+" ")])],1)]}}],null,false,3155207578),model:{value:(_vm.selectedPrediction),callback:function ($$v) {_vm.selectedPrediction=$$v},expression:"selectedPrediction"}}):_vm._e(),(!_vm.formatedPredictions.length && !_vm.$wait.is(("fetching predictions for " + (_vm.$attrs.id))) && !_vm.hasError)?_c('ctk-input-address-predictions-empty',[(_vm.hasHelp)?_c('ctk-input-address-predictions-help',{directives:[{name:"matomo",rawName:"v-matomo",value:({
        insert: { category: 'Quotations', action: 'Address Not Found Help Message' },
        click: { category: 'Quotations', action: 'Address Not Found Help Click', native: false },
      }),expression:"{\n        insert: { category: 'Quotations', action: 'Address Not Found Help Message' },\n        click: { category: 'Quotations', action: 'Address Not Found Help Click', native: false },\n      }"}],staticClass:"tw-mt-3",attrs:{"direction":_vm.direction,"content":_vm.$t('app.paragraphs.need_help_address')}}):_vm._e()],1):_vm._e(),(_vm.hasError)?_c('ctk-input-address-predictions-empty',{scopedSlots:_vm._u([{key:"no-result",fn:function(){return [_c('div')]},proxy:true}],null,false,3553719830)},[_c('ctk-input-address-predictions-help',{directives:[{name:"matomo",rawName:"v-matomo",value:({
        insert: { category: 'Quotations', action: 'Address Imprecise Help Message' },
        click: { category: 'Quotations', action: 'Address Imprecise Help Click', native: false }
      }),expression:"{\n        insert: { category: 'Quotations', action: 'Address Imprecise Help Message' },\n        click: { category: 'Quotations', action: 'Address Imprecise Help Click', native: false }\n      }"}],staticClass:"ctk-input-address-predictions-help__not-precise",attrs:{"direction":_vm.direction,"content":_vm.$t('app.paragraphs.error.address_not_precise')}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }