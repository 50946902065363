export default class BaseAddressProvider {
  constructor (allowedCountries = []) {
    this.allowedCountries = []
    this.setCountries(allowedCountries || [])
  }

  /**
   * @method setCountries
   * @param {Array} countries
   */
  setCountries (countries) {
    this.allowedCountries = countries
  }
}
