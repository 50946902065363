<template>
  <checked-items-list
    class="offer-reassurance-list"
    :items="items"
  />
</template>

<script>
  import CheckedItemsList from '@/components/CheckedItemsList/index.vue'

  const OFFERS_REASSURANCE_ITEMS = {
    chronotruck: [
      'app.paragraphs.offers.reassurances.carrier_lookup',
      'app.paragraphs.offers.reassurances.indicative_price',
      'app.paragraphs.offers.reassurances.direct_routing',
      'app.paragraphs.offers.reassurances.all_vehicles'
    ],
    pallet_network: [
      'app.paragraphs.offers.reassurances.immediate_assignment',
      'app.paragraphs.offers.reassurances.guaranteed_price',
      'app.paragraphs.offers.reassurances.dock',
      'app.paragraphs.offers.reassurances.heavy_truck_only'
    ],
    corridor: [
      'app.paragraphs.offers.reassurances.delayed_assignment',
      'app.paragraphs.offers.reassurances.guaranteed_price',
      'app.paragraphs.offers.reassurances.direct_routing',
      'app.paragraphs.offers.reassurances.all_vehicles'
    ]
  }

  /**
   * @module component - OfferReassuranceList
   * @param {string} offerType
   */
  export default {
    name: 'OfferReassuranceList',
    components: {
      CheckedItemsList
    },
    props: {
      offerType: {
        type: String,
        required: true
      }
    },
    computed: {
      /**
       * @function items
       * @returns {Array<string>}
       */
      items () {
        // @ts-ignore
        return OFFERS_REASSURANCE_ITEMS[this.offerType].map(item => this.$t(item))
      }
    }
  }
</script>
