<template>
  <ctk-dialog
    v-model="dialogValue"
    id="compare-dialog"
    modal-class="new-shipment-quotation-compare-dialog"
    max-width="1130px"
    hide-header
    hide-footer
    persistent
  >
    <div class="tw-p-4 md:tw-p-10">
      <h1
        v-text="$t(getQuotation && getQuotation.prices && getQuotation.prices.length > 1
          ? 'shipments.titles.price_compare'
          : 'shipments.titles.price_understanding')"
        class="new-shipment-quotation-compare-dialog__title tw-relative tw-text-2xl tw-font-medium tw-mb-12 tw-mr-2"
        data-test="title"
      />

      <ctk-dialog-close-button
        class="tw-top-0 tw-right-0"
        data-test="close"
        @click.native="dialogValue = false"
      />

      <div class="new-shipment-quotation-compare-dialog__table tw-mb-5 tw-flex tw-flex-col md:tw-flex-row md:tw-flex-wrap">
        <template
          v-for="(price, k) in prices"
        >
          <div
            :key="`${k}-head`"
            :class="[
              k !== 'corridor' && 'md:tw-mr-5',
              isPriceDisabled(k) && 'new-shipment-quotation-compare-dialog__table__head--disabled'
            ]"
            :data-test="`${k}-head`"
            class="new-shipment-quotation-compare-dialog__table__head tw-flex tw-items-center tw-text-white tw-text-lg tw-text-center tw-bg-blue-500 tw-order-0 tw-rounded-tl tw-rounded-tr"
          >
            <div
              class="tw-mx-auto tw-flex tw-flex-col"
            >
              <span
                v-text="offers[k]"
              />
              <span
                v-if="k === 'pallet_network'"
                v-text="`(${$t('new-shipment.titles.pallet_network_meaning')})`"
                class="tw-text-sm"
              />
            </div>
          </div>
          <div
            v-if="hasPrice"
            :key="`${k}-price`"
            :class="[
              k !== 'corridor' && 'md:tw-mr-5',
              isPriceDisabled(k) && 'new-shipment-quotation-compare-dialog__table__price--disabled'
            ]"
            :data-test="`${k}-price`"
            class="new-shipment-quotation-compare-dialog__table__price tw-bg-white tw-text-blue-500 tw-order-1"
          >
            <div class="tw-flex tw-flex-col tw-justify-center tw-m-auto tw-text-center tw-py-8">
              <template
                v-if="getPrice(k) && k !== 'pallet_network'"
              >
                <span
                  v-text="$t('price_excl_tax', {
                    price: $options.filters.currency(getPrice(k).total, getPrice(k).currency, $i18n.locale)
                  })"
                  class="tw-font-bold tw-text-xl"
                  data-test="vat-excluded"
                />
                <span
                  v-text="$t('price_incl_tax', {
                    price: $options.filters.currency(getPrice(k).total_including_vat, getPrice(k).currency, $i18n.locale)
                  })"
                  class="tw-text-sm tw-italic"
                  data-test="vat-included"
                />
              </template>
              <template
                v-else
              >
                <template
                  v-if="k === 'pallet_network'"
                >
                  <div class="tw-flex">
                    <ui-ctk-icon
                      name="warning-full"
                      class="tw-text-yellow-500 tw-text-3xl tw-mr-1"
                      data-test="icon"
                    />
                    <i18n
                      class="tw-italic tw-mb-0 tw-leading-tight tw-text-left"
                      path="new-shipment.paragraphs.offer_unavailable"
                      tag="p"
                      data-test="unavailable-message"
                    >
                      <template #name>
                        <span>Laura Pinto</span>
                      </template>
                      <template #phone>
                        <span
                          v-text="phone.text"
                          class="tw-whitespace-nowrap"
                        />
                      </template>
                    </i18n>
                  </div>
                </template>
                <template v-else>
                  <span
                    v-text="$t('shipments.paragraphs.price_compare.no_price')"
                    class="tw-text-sm tw-px-4"
                  />
                </template>
              </template>
            </div>
          </div>
          <template
            v-for="section in formattedPrice(price)"
          >
            <div
              :key="`${k}-${section.name}-header`"
              :style="`order: ${section.order};`"
              :class="[
                k !== 'corridor' && 'md:tw-mr-5',
                isPriceDisabled(k) && 'new-shipment-quotation-compare-dialog__table__subheader--disabled'
              ]"
              :data-test="`${k}-header-${section.name}`"
              class="new-shipment-quotation-compare-dialog__table__subheader tw-flex tw-items-center tw-text-center tw-text-blue-500 tw-font-bold"
            >
              <span
                v-text="$t(section.name)"
                class="tw-mx-auto"
              />
            </div>
            <div
              v-for="(item, item_index) in section.items"
              :key="`${k}-${section.name}-${item.value}`"
              :style="`order: ${item.order};`"
              :class="[
                k !== 'corridor' && 'md:tw-mr-5',
                item_index === 0 && 'tw-pt-5',
                isPriceDisabled(k) && 'new-shipment-quotation-compare-dialog__table__items--disabled'
              ]"
              :data-test="`${k}-item-${section.name}-${item.value}`"
              class="new-shipment-quotation-compare-dialog__table__items tw-flex tw-px-4 tw-bg-white"
            >
              <div
                class="tw-w-5 tw-mr-3"
              >
                <div
                  class="new-shipment-quotation-compare-dialog__table__items__check tw-flex tw-items-center tw-w-4 tw-h-4 tw-rounded-full tw-bg-blue-500 tw-text-white tw-select-none tw-mt-1"
                >
                  <ui-material-icon
                    class="tw-text-xs tw-m-auto"
                    name="check"
                  />
                </div>
              </div>
              <div class="tw-flex tw-flex-col">
                <p
                  v-text="$t(item.value)"
                />
                <!-- TODO: Dirty workaround. Needs refactor -->
                <template
                  v-if="item.value === 'shipments.paragraphs.price_compare.load_typology.pallets'"
                >
                  <p
                    class="tw-mb-1"
                    v-text="$t('shipments.paragraphs.price_compare.load_typology.accepted_formats')"
                  />
                  <ul class="tw-p-0 tw-list-none">
                    <li class="tw-mb-1">
                      <div
                        v-text="`- ${$tc('app.values.pallet', 4, {
                          count: $n(4)
                        })} 120x100*`"
                      />
                      <div
                        v-text="$t('shipments.paragraphs.price_compare.load_typology.max_weight_total')"
                      />
                    </li>
                    <li class="tw-mb-1">
                      <div
                        v-text="`- ${$tc('app.values.pallet', 6, {
                          count: $n(6)
                        })} 120x80*`"
                      />
                      <div
                        v-text="$t('shipments.paragraphs.price_compare.load_typology.max_weight_total')"
                      />
                    </li>
                    <li>
                      <div
                        v-text="`- ${$tc('app.values.pallet', 12, {
                          count: $n(12)
                        })} 60x80`"
                      />
                      <div
                        v-text="$t('shipments.paragraphs.price_compare.load_typology.max_weight')"
                      />
                    </li>
                  </ul>
                  <p
                    v-text="$t('shipments.paragraphs.price_compare.load_typology.pallets_de_be')"
                  />
                </template>
              </div>
            </div>
          </template>

          <div
            :key="`${k}-footer`"
            :class="[
              k !== 'corridor' && 'md:tw-mr-5',
              isPriceDisabled(k) && 'new-shipment-quotation-compare-dialog__table__footer--disabled'
            ]"
            :data-test="`${k}-footer`"
            style="order: 24;"
            class="new-shipment-quotation-compare-dialog__table__footer tw-flex tw-items-center tw-pb-8 tw-pt-4 tw-bg-white tw-rounded-bl tw-rounded-br tw-mb-8 md:tw-mb-0"
          >
            <template
              v-if="hasActions && !isPriceDisabled(k)"
            >
              <template
                v-if="hasPrice"
              >
                <ui-button
                  v-if="getQuotation && getQuotation.prices && getQuotation.prices.length > 1"
                  class="tw-text-white tw-rounded-full tw-mx-auto tw-px-4"
                  variant="primary"

                  type="submit"
                  @click="selectPrice(k)"
                >
                  {{ $t('app.buttons.select') }}
                </ui-button>
              </template>
              <ui-button
                v-else
                :to="{
                  name: 'NewShipment'
                }"
                class="tw-text-white tw-rounded-full tw-mx-auto tw-px-4"
                variant="primary"

                v-matomo="{
                  click: { category: 'Dashboard', action: 'Click New Shipment', name: k }
                }"
                @click.native="dialogValue = false"
              >
                {{ $t('navigation.new-shipment') | capitalize }}
              </ui-button>
            </template>
          </div>
        </template>
      </div>

      <ul
        class="new-shipment-quotation-compare-dialog__notes tw-text-xs tw-mb-5 tw-list-none tw-p-0"
        data-test="footer-notes"
      >
        <li
          v-text="$t('shipments.paragraphs.price_compare.countries', {
            countries_list: countries
          })"
          class="tw-mb-1"
          data-test="countries-list"
        />
        <li
          v-text="$t('shipments.paragraphs.price_compare.fragile_load')"
          class="tw-mb-1"
          data-test="fragile-load"
        />
        <li
          v-text="$t('shipments.paragraphs.price_compare.corridors', {
            corridors_list: corridors
          })"
          data-test="corridors"
        />
      </ul>

      <new-shipment-quotation-compare-dialog-contact />
    </div>
  </ctk-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { availableCountries, corridors, offers, phones } from '@/../config/chronoinfos'
  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  import CtkDialog from '@/components/CtkDialog/index.vue'
  import CtkDialogCloseButton from '@/components/CtkDialog/_subs/CtkDialogCloseButton/index.vue'

  import NewShipmentQuotationCompareDialogContact from './_subs/NewShipmentQuotationCompareDialogContact/index.vue'

  /**
   * @module component - NewShipmentQuotationCompareDialog
   * @param {boolean} [hasPrice=true] - Specify if the price should be shown or not
   * @param {boolean} [hasActions=true] - Specify if the action buttons should be shown or not
   */
  export default {
    name: 'NewShipmentQuotationCompareDialog',
    components: {
      CtkDialog,
      CtkDialogCloseButton,
      NewShipmentQuotationCompareDialogContact
    },
    props: {
      value: {
        type: Boolean,
        default: true
      },
      hasPrice: {
        type: Boolean,
        default: true
      },
      hasActions: {
        type: Boolean,
        default: true
      }
    },
    setup (props) {
      const { state: dialogValue } = useModelGetterSetter(props, 'value')

      return {
        dialogValue,
        phone: phones.laura
      }
    },
    data () {
      return {
        offers
      }
    },
    computed: {
      ...mapGetters('shipments/new-shipment', [
        'getQuotation'
      ]),
      /**
       * @function prices
       * @returns {object}
       */
      prices () {
        return {
          chronotruck: [
            {
              name: 'shipments.titles.price_compare.carrier',
              items: [
                'shipments.paragraphs.price_compare.carrier.seeking_carrier'
              ]
            },
            {
              name: 'shipments.titles.price_compare.routing',
              items: [
                'shipments.paragraphs.price_compare.routing.dates_specified',
                'shipments.paragraphs.price_compare.routing.accessibility',
                'shipments.paragraphs.price_compare.routing.direct'
              ]
            },
            {
              name: 'shipments.titles.price_compare.price',
              items: [
                'shipments.paragraphs.price_compare.price.flexible'
              ]
            },
            {
              name: 'shipments.titles.price_compare.load_typology',
              items: [
                'shipments.paragraphs.price_compare.load_typology.any_load'
              ]
            },
            {
              name: 'shipments.titles.price_compare.zones',
              items: [
                'shipments.paragraphs.price_compare.zones.all'
              ]
            }
          ],
          pallet_network: [
            {
              name: 'shipments.titles.price_compare.carrier',
              items: [
                'shipments.paragraphs.price_compare.carrier.instant'
              ]
            },
            {
              name: 'shipments.titles.price_compare.routing',
              items: [
                'shipments.paragraphs.price_compare.routing.dates_imposed',
                'shipments.paragraphs.price_compare.routing.accessibility_heavytruck',
                'shipments.paragraphs.price_compare.routing.dock'
              ]
            },
            {
              name: 'shipments.titles.price_compare.price',
              items: [
                'shipments.paragraphs.price_compare.price.fixed'
              ]
            },
            {
              name: 'shipments.titles.price_compare.load_typology',
              items: [
                'shipments.paragraphs.price_compare.load_typology.pallets'
              ]
            },
            {
              name: 'shipments.titles.price_compare.zones',
              items: [
                'shipments.paragraphs.price_compare.zones.france'
              ]
            }
          ],
          corridor: [
            {
              name: 'shipments.titles.price_compare.carrier',
              items: [
                'shipments.paragraphs.price_compare.carrier.secured'
              ]
            },
            {
              name: 'shipments.titles.price_compare.routing',
              items: [
                'shipments.paragraphs.price_compare.routing.dates_specified',
                'shipments.paragraphs.price_compare.routing.accessibility',
                'shipments.paragraphs.price_compare.routing.direct'
              ]
            },
            {
              name: 'shipments.titles.price_compare.price',
              items: [
                'shipments.paragraphs.price_compare.price.fixed_lines'
              ]
            },
            {
              name: 'shipments.titles.price_compare.load_typology',
              items: [
                'shipments.paragraphs.price_compare.load_typology.any_load'
              ]
            },
            {
              name: 'shipments.titles.price_compare.zones',
              items: [
                'shipments.paragraphs.price_compare.zones.corridors'
              ]
            }
          ]
        }
      },
      /**
       * Returns a formatted list of all the available corridors
       * @function corridors
       * @returns {Array<string>}
       */
      corridors: () => corridors
        .map((/** @type {Array<any>} */ v) => {
          const innerCorridors = [
            `${v[0]}>${v[1]}`
          ]

          if (v[2]) {
            innerCorridors.push(`${v[1]}>${v[0]}`)
          }

          return `(${innerCorridors.join(' - ')})`
        })
        .join(' - '),
      /**
       * Returns a formatted list of available countries
       * @function countries
       * @returns {string}
       */
      countries () {
        return availableCountries
          .map((/** @type {any} */ v) => this.$t(v['iso-2']))
          .join(', ')
      },
      /**
       * Returns true if the price should be disabled or not
       * @function isPriceDisabled
       * @param {string} priceType
       */
      isPriceDisabled () {
        return (/** @type {string} */ priceType) => {
          /**
           * Force pallet network offer to be unavailable. This is supposed to be temporary.
           */
          if (priceType === 'pallet_network') return true

          return this.hasPrice && !this.getPrice(priceType)
        }
      },
      /**
       * @function getPrice
       * @returns {object}
       */
      getPrice () {
        return (/** @type {string} */ priceType) => {
          const quotation = this.getQuotation
          if (!quotation || !quotation.prices) return

          const { prices } = quotation

          return prices && prices
            .find((/** @type {any} */ price) => price.type === priceType)
        }
      },
      /**
       * @function formattedPrice
       * @returns {any}
       */
      formattedPrice () {
        return price => {
          let increment = 1

          return price.map(v => {
            increment += 1

            const sectionOrder = increment
            const items = v.items.map(item => {
              increment += 1

              return {
                value: item,
                order: increment
              }
            })

            return {
              ...v,
              items,
              order: sectionOrder
            }
          })
        }
      }
    },
    methods: {
      /**
       * @function selectPrice
       * @param {string} priceType
       */
      selectPrice (priceType) {
        this.$emit('select-price', priceType)
        this.dialogValue = false
      }
    }
  }
</script>

<style lang="scss">
.new-shipment-quotation-compare-dialog .modal-container {
  width: 100%;
  margin-bottom: 1rem;
  background-color: #F5F5F5;
  max-height: initial;
}
.new-shipment-quotation-compare-dialog .modal-wrapper {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.new-shipment-quotation-compare-dialog__title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -0.5rem;
  width: 190px;
  height: 1px;
  background-color: $secondary-text;
}
.new-shipment-quotation-compare-dialog__table__head {
  height: 85px;
}
.new-shipment-quotation-compare-dialog__table__head--disabled {
  background-color: $gray;
  color: $secondary-text;
  font-weight: 700;
}
@media only screen and (min-width: $breakpoint-tablet) {
  .new-shipment-quotation-compare-dialog__table__head, .new-shipment-quotation-compare-dialog__table__subheader, .new-shipment-quotation-compare-dialog__table__items, .new-shipment-quotation-compare-dialog__table__price, .new-shipment-quotation-compare-dialog__table__footer {
    width: 30%;
    width: calc(33.33% - 1px - 0.78rem);
  }
}
@media only screen and (max-width: $breakpoint-tablet) {
  .new-shipment-quotation-compare-dialog__table__head, .new-shipment-quotation-compare-dialog__table__subheader, .new-shipment-quotation-compare-dialog__table__items, .new-shipment-quotation-compare-dialog__table__price, .new-shipment-quotation-compare-dialog__table__footer {
    order: 0 !important;
  }
}
.new-shipment-quotation-compare-dialog__table__head, .new-shipment-quotation-compare-dialog__table__subheader, .new-shipment-quotation-compare-dialog__table__items, .new-shipment-quotation-compare-dialog__table__price, .new-shipment-quotation-compare-dialog__table__footer {
  border-left: 1px solid $info;
  border-right: 1px solid $info;
}
.new-shipment-quotation-compare-dialog__table__head--disabled, .new-shipment-quotation-compare-dialog__table__subheader--disabled, .new-shipment-quotation-compare-dialog__table__items--disabled, .new-shipment-quotation-compare-dialog__table__price--disabled, .new-shipment-quotation-compare-dialog__table__footer--disabled {
  border-left: 1px solid $gray;
  border-right: 1px solid $gray;
}
.new-shipment-quotation-compare-dialog__table__items--disabled, .new-shipment-quotation-compare-dialog__table__price--disabled, .new-shipment-quotation-compare-dialog__table__subheader--disabled {
  color: $secondary-text;
}
.new-shipment-quotation-compare-dialog__table__items--disabled .new-shipment-quotation-compare-dialog__table__items__check {
  background-color: $gray;
  color: $secondary-text;
}
.new-shipment-quotation-compare-dialog__table__footer {
  border-bottom: 1px solid $info;
}
.new-shipment-quotation-compare-dialog__table__footer--disabled {
  border-bottom: 1px solid $gray;
}
.new-shipment-quotation-compare-dialog__table__subheader {
  background-color: rgba(#277696, 0.3);
  height: 26px;
}
.new-shipment-quotation-compare-dialog__table__subheader--disabled {
  background-color: $gray;
}
.new-shipment-quotation-compare-dialog__notes {
  color: $secondary-text;
}
</style>
