<template>
  <div class="new-shipment-quotation-learn-more-dialog-accessibility tw-rounded tw-p-3">
    <h3
      class="tw-flex tw-items-end md:tw-justify-center tw-text-blue-500 tw-mb-0"
      data-test="title"
    >
      <ui-ctk-icon
        class="tw-text-2xl tw--ml-2"
        name="warning"
        data-test="icon"
      />
      <span
        v-text="$t('new-shipment.titles.learn_more.accessibility')"
        class="tw-text-sm tw-font-medium tw-mb-1"
        data-test="content"
      />
    </h3>
    <p
      v-text="$t('new-shipment.paragraphs.learn_more.accessibility')"
      class="new-shipment-quotation-learn-more-dialog-accessibility__content tw-text-sm md:tw-text-xs md:tw-text-center md:tw-px-6 tw-m-0"
      data-test="paragraph"
    />
  </div>
</template>

<script>
  /**
   * @module component - NewShipmentQuotationLearnMoreDialogAccessibility
   */
  export default {
    name: 'NewShipmentQuotationLearnMoreDialogAccessibility'
  }
</script>

<style lang="scss" scoped>

  .new-shipment-quotation-learn-more-dialog-accessibility {
    background-color: rgba($info, 0.1);

    &__content {
      color: $secondary-text;
    }
  }

</style>
