import { isTesting } from '@/../utils/env'
import Config from '@/services/Config'

import BaseAddressProvider from './BaseAddressProvider'
import AddressComponent from '@/models/AddressComponent'

const DEFAULT_TEST_ADDRESS = new AddressComponent({
  formatted_address: '8 rue Auber, 75009 Paris, France',
  address_components: [
    {
      long_name: '8',
      short_name: '8',
      types: ['street_number']
    },
    {
      long_name: 'rue Auber',
      short_name: 'rue Auber',
      types: ['route']
    },
    {
      long_name: 'Paris',
      short_name: 'Paris',
      types: ['locality']
    },
    {
      long_name: 'France',
      short_name: 'FR',
      types: ['country']
    },
    {
      long_name: '75009',
      short_name: '75009',
      types: ['postal_code']
    }
  ],
  geometry: {
    location: {
      lat: () => 40,
      lng: () => 20
    }
  }
})

export default class GoogleAddressProvider extends BaseAddressProvider {
  /**
   * Returns a list of predictions according to a query
   * @method getPredictions
   * @param {string} query
   * @returns {Promise<Array>} result
   */
  getPredictions (query) {
    return new Promise(resolve => {
      /**
       * If we are in the testing environment, ignore the Google autocomplete thing and
       * return a predefined list of addresses for tesing purposes; since we don't want
       * to use Google API during tests.
       */
      if (isTesting) {
        resolve([
          {
            id: 'CHRONOTRUCK',
            description: 'Chronotruck, Rue Auber, Paris, France',
            name: 'Chronotruck',
            types: [
              'establishment'
            ],
            provider: 'GoogleAddressProvider'
          }
        ])
        return false
      }

      if (typeof window.google === 'undefined' || typeof window.google.maps === 'undefined') {
        /**
         * TODO: Handle promise rejection
         */
        resolve([])
        return false
      }

      const autocompleteService = new window.google.maps.places.AutocompleteService()
      autocompleteService.getPlacePredictions({
        input: query,
        componentRestrictions: {
          country: this.allowedCountries[0]
        }
      }, (predictions, status) => {
        /**
         * See: https://developers.google.com/places/web-service/supported_types
         * @const filterTypes
         */
        const filterTypes = ['street_address', 'route', 'establishment', 'premise']

        /**
         * See: https://developers.google.com/maps/documentation/javascript/reference/places-service#PlacesServiceStatus
         * @const status
         */
        if (status === 'ZERO_RESULTS') {
          resolve([])
        }

        if (status === 'OK') {
          const filteredPredictions = predictions.filter(prediction => {
            return prediction.types.findIndex(p => filterTypes.includes(p)) !== -1
          })

          resolve(filteredPredictions.map(prediction => ({
            id: prediction.place_id,
            description: prediction.description,
            name: prediction.structured_formatting && prediction.structured_formatting.main_text
              ? prediction.structured_formatting.main_text
              : null,
            types: prediction.types,
            provider: 'GoogleAddressProvider'
          })))
        }
      })
    })
  }

  /**
   * Returns a detailed address item
   * @method getItem
   * @param {string} id
   * @returns {Promise<AddressComponent|Error>} result
   */
  getItem (id) {
    return new Promise((resolve, reject) => {
      /**
       * If we are in the testing environment, ignore the Google autocomplete thing and
       * return a predefined list of addresses for tesing purposes; since we don't want
       * to use Google API during tests.
       */
      if (isTesting) {
        resolve(DEFAULT_TEST_ADDRESS)
        return false
      }

      if (typeof window.google === 'undefined' || typeof window.google.maps === 'undefined') {
        return reject(new Error('Google Maps script is not injected.'))
      }

      const attributionElement = document.createElement('div')
      const service = new window.google.maps.places.PlacesService(attributionElement)
      const request = {
        placeId: id,
        fields: [
          'address_component',
          'geometry'
        ]
      }

      service.getDetails(request, (result, status) => {
        if (status === 'OK') {
          if (result) {
            resolve(new AddressComponent(result))
          } else {
            reject(new Error(status))
          }
        } else {
          reject(new Error(status))
        }
      })
    })
  }

  /**
   * @method geocode
   * @param {object} fields
   * @param {string} fields.city
   * @param {string} fields.address
   * @param {string} fields.country
   * @returns {Promise<any>}
   */
  geocode (fields) {
    const geocoder = new window.google.maps.Geocoder()
    return new Promise((resolve, reject) => {
      if (isTesting) {
        return resolve([
          DEFAULT_TEST_ADDRESS,
          DEFAULT_TEST_ADDRESS,
          DEFAULT_TEST_ADDRESS
        ])
      }

      geocoder.geocode({
        address: `${fields.address}, ${fields.postalCode} ${fields.city}, ${fields.country}`,
        componentRestrictions: {
          administrativeArea: fields.city,
          country: fields.country
        }
      }, (results, status) => {
        switch (status) {
          case 'OK':
            resolve(results.map(result => new AddressComponent(result)))
            break
          case 'NOT_FOUND':
          case 'ZERO_RESULTS':
            resolve([])
            break
          case 'INVALID_REQUEST':
          case 'OVER_QUERY_LIMIT':
          case 'REQUEST_DENIED':
          case 'UNKNOWN_ERROR':
            reject(new Error(`Could not geocode the address: ${status}`))
            break
        }
      })
    })
  }

  /**
   * Inject the Google Maps script to the page.
   * @method inject
   * @param {?any} [callback] - Function called whenever the script is fully loaded
   */
  inject (callback) {
    if ((window.google && window.google.maps) || window._google_maps_injected) {
      console.log('Google Maps script already injected.')
      return
    }

    const s = document.createElement('script')
    s.setAttribute('src', `https://maps.googleapis.com/maps/api/js?libraries=places&key=${Config.get('maps.id')}`)
    s.setAttribute('type', 'text/javascript')
    s.onload = callback
    document.body.appendChild(s)
    window._google_maps_injected = true
  }
}
