<template>
  <div
    :class="{
      'new-shipment-quotation-learn-more-dialog-step-item--secondary': secondary
    }"
    class="new-shipment-quotation-learn-more-dialog-step-item"
  >
    <h3
      v-text="title"
      class="new-shipment-quotation-learn-more-dialog-step-item__title tw-relative tw-text-sm tw-text-blue-500 tw-font-medium tw-mb-0"
      data-test="title"
    />
    <div
      :class="{
        'new-shipment-quotation-learn-more-dialog-step-item__dock': dock
      }"
    >
      <div
        v-if="dock"
        class="new-shipment-quotation-learn-more-dialog-step-item__dock__label md:tw-hidden tw-italic"
        v-text="$t('new-shipment.labels.first_vehicle')"
      />
      <p
        v-text="content"
        class="new-shipment-quotation-learn-more-dialog-step-item__content fs-13 tw-leading-tight"
        data-test="content"
      />
      <div
        v-if="dock"
        class="new-shipment-quotation-learn-more-dialog-step-item__dock__label md:tw-hidden tw-italic"
        v-text="$t('new-shipment.labels.second_vehicle')"
      />
    </div>
  </div>
</template>

<script>
  /**
   * @module component - NewShipmentQuotationLearnMoreDialogStepItem
   * @param {string} title
   * @param {string} content
   * @param {boolean} [secondary=false]
   * @param {boolean} [dock=false]
   */
  export default {
    name: 'NewShipmentQuotationLearnMoreDialogStepItem',
    props: {
      title: {
        type: String,
        required: true
      },
      content: {
        type: String,
        required: true
      },
      secondary: {
        type: Boolean,
        default: false
      },
      dock: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>

  .new-shipment-quotation-learn-more-dialog-step-item {
    position: relative;

    @media only screen and (max-width: $breakpoint-tablet) {
      &__dock {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          width: 1px;
          height: calc(100% - 2.6rem);
          left: 4px;
          top: 1.3rem;
          border-left: 1px dashed $secondary-text;
        }

        &__label {
          color: $secondary-text;
          background-color: white;
          margin-bottom: 0.5rem;
        }

        .new-shipment-quotation-learn-more-dialog-step-item__content {
          padding-left: 1rem;
          margin-bottom: 0.5rem;
        }
      }
    }

    &__title,
    & {
      &::before {
        content: '';
        position: absolute;
      }
    }

    &::before {
      width: 1px;
      height: 100%;
      background-color: $info;
      left: 0.5rem;
      top: 0.25rem;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      &:last-child::before {
        display: none;
      }
    }

    &__title {
      padding-top: 0.25rem;

      &::before {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: $info;
        left: -1rem;
        bottom: 0;
        top: 0;
        margin: auto;
      }
    }

    &__content {
      color: $secondary-text;
    }

    &--secondary {
      &,
      .new-shipment-quotation-learn-more-dialog-step-item__title {
        color: $secondary-text;

        &::before {
          background-color: #A4A4A4 !important;
        }
      }
    }

    @media screen and (min-width: $breakpoint-tablet) {
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: $info;
        left: 0;
        top: 0;
      }

      &::before {
        margin: auto;
        left: 0;
        right: 0;
      }

      &:nth-child(even) {
        margin-top: 8rem;

        .new-shipment-quotation-learn-more-dialog-step-item__title {
          &::before {
            top: calc(-8rem - 5px);
            left: 0;
            right: 0;
            bottom: auto;
          }
        }

        &::before {
          height: 8rem;
          top: -8rem;
        }
      }

      &:nth-child(odd) {
        margin-top: 4rem;

        .new-shipment-quotation-learn-more-dialog-step-item__title {
          &::before {
            top: calc(-4rem - 5px);
            left: 0;
            right: 0;
            bottom: auto;
          }
        }

        &::before {
          height: 4rem;
          top: -4rem;
        }
      }

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

</style>
