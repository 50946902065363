import axios, { CancelToken } from 'axios'
import store from '@/store'
import { i18n } from '@/locales'
import { Address } from '@/resources'

import BaseAddressProvider from './BaseAddressProvider'
import AddressComponent from '@/models/AddressComponent'

/**
 * Returns a prediction list, from the addresses list
 * @function getPredictionsFromAddresses
 * @returns {Array} predictions
 */
export const getPredictionsFromAddresses = (addresses) => {
  return addresses.map((item, k) => {
    const {
      street_name: streetName,
      city,
      name,
      country
    } = item.address

    return {
      id: k,
      description: `${name}, ${streetName}, ${city}, ${i18n.t(country)}`,
      name: name,
      source: item,
      types: ['saved'],
      provider: 'ChronotruckAddressProvider'
    }
  })
}

export default class ChronotruckAddressProvider extends BaseAddressProvider {
  constructor () {
    super()

    this.previousRequest = null
    this.savedPredictions = {
      pickup: [],
      delivery: []
    }
  }

  fetchAddresses (query, type) {
    const source = CancelToken.source()
    const req = Address.get({
      cid: store.getters['auth/getCid']
    }, {
      params: {
        q: query,
        limit: 30,
        type
      },
      cancelToken: source.token
    })

    return {
      cancelTokenSource: source,
      request: req
    }
  }

  /**
   * Returns a list of predictions according to a query
   * @method getPredictions
   * @param {string} query
   * @param {string} type - Either "pickup" or "delivery"
   * @returns {Promise<Array>} result
   */
  getPredictions (query, type) {
    return new Promise(resolve => {
      /**
       * Abort the pending request, if any before sending another one
       */
      if (this.previousRequest) {
        try {
          this.previousRequest.cancelTokenSource.cancel()
        } catch (e) {
          console.error('Could not abort the request', e)
        }
      }

      const { cancelTokenSource, request } = this.fetchAddresses(query, type)
      this.previousRequest = { cancelTokenSource, request }

      request
        .then(res => {
          if (res.data.meta.item_count === 0) {
            resolve([])
          }

          const predictions = getPredictionsFromAddresses(res.data.items.filter(address => {
            return address.address && address.address.country && this.allowedCountries.includes(address.address.country)
          }))

          this.savedPredictions[type] = predictions

          resolve(predictions)
        })
        .catch(err => {
          if (!axios.isCancel(err)) {
            if (!err.response) return resolve([])

            console.error('Error occured while searching a shipment', err)
          }

          resolve([])
        })
    })
  }

  /**
   * Returns a detailed address item
   * @method getItem
   * @param {string} id
   * @param {string} type - Either "pickup" or "delivery"
   * @param {Array} predictionsBag - A list of custom predictions to search for
   * @returns {Promise<AddressComponent|Error>} result
   */
  getItem (id, type, predictionsBag = null) {
    return new Promise((resolve, reject) => {
      const listPredictions = predictionsBag || this.savedPredictions[type]
      const prediction = listPredictions
        .find(prediction => prediction.id === id)

      if (prediction) {
        if (!prediction.source) reject(new Error('INVALID_PREDICTION'))

        const {
          street_name: streetName,
          city,
          postal_code: postalCode,
          name,
          country,
          location
        } = prediction.source.address

        resolve(new AddressComponent({
          formatted_address: `${name}, ${streetName}, ${city}, ${i18n.t(country)}`,
          address_components: [
            {
              long_name: streetName,
              types: ['route']
            },
            {
              long_name: city,
              types: ['locality']
            },
            {
              short_name: country,
              types: ['country']
            },
            {
              short_name: postalCode,
              types: ['postal_code']
            }
          ],
          geometry: {
            location: {
              lat: () => location.lat,
              lng: () => location.lng
            }
          }
        }))
      } else {
        reject(new Error('NOT_FOUND'))
      }
    })
  }
}
