<template>
  <div class="new-shipment-quotation-learn-more-dialog-guarantees">
    <h2
      data-test="title"
      v-text="$t('new-shipment.titles.learn_more.guarantees.title', {
        offer_name: offers[priceType]
      })"
      class="new-shipment-quotation-learn-more-dialog-guarantees__title tw-relative tw-text-sm tw-mb-6"
    />

    <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-items-start">
      <div
        v-for="(guarantee, k) in guarantees"
        :key="k"
        :data-test="k"
        class="new-shipment-quotation-learn-more-dialog-guarantees__item md:tw-w-1/3"
      >
        <h3
          class="tw-text-green-500 tw-font-medium tw-text-sm tw-flex tw-items-center tw-mb-0"
          data-test="title"
        >
          <div
            class="tw-flex tw-w-8 tw--ml-1"
          >
            <ui-ctk-icon
              :name="guarantee.icon"
              class="tw-my-auto tw-text-3xl"
              data-test="icon"
            />
          </div>

          <span
            v-text="$t(guarantee.title, {
              offer_name: offers.pallet_network
            })"
            data-test="title-content"
          />
        </h3>
        <p
          v-text="$t(guarantee.content, {
            offer_name: offers.pallet_network
          })"
          data-test="content"
          class="new-shipment-quotation-learn-more-dialog-guarantees__item__content fs-13 tw-mb-0 tw-leading-tight"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import { offers } from '@/../config/chronoinfos'

  /**
   * @module component - NewShipmentQuotationLearnMoreDialogGuarantees
   * @param {string} priceType
   */
  export default defineComponent({
    name: 'NewShipmentQuotationLearnMoreDialogGuarantees',
    props: {
      priceType: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        offers
      }
    },
    computed: {
      guarantees () {
        /** @type {object} */
        const guarantees = {
          pallet_network: {
            fixed_price: {
              title: 'new-shipment.titles.learn_more.guarantees.fixed_price',
              content: 'new-shipment.paragraphs.learn_more.guarantees.fixed_price',
              icon: 'budget'
            },
            guarantee: {
              title: 'new-shipment.titles.learn_more.guarantees.guarantee',
              content: 'new-shipment.paragraphs.learn_more.guarantees.guarantee',
              icon: 'guarantee'
            },
            certified: {
              title: 'new-shipment.titles.learn_more.guarantees.certified',
              content: 'new-shipment.paragraphs.learn_more.guarantees.certified',
              icon: 'certified'
            }
          },
          chronotruck: {
            linking: {
              title: 'new-shipment.titles.learn_more.guarantees.linking',
              content: 'new-shipment.paragraphs.learn_more.guarantees.linking',
              icon: 'linking'
            },
            cancellation: {
              title: 'new-shipment.titles.learn_more.guarantees.cancellation',
              content: 'new-shipment.paragraphs.learn_more.guarantees.cancellation',
              icon: 'cancel'
            },
            certified: {
              title: 'new-shipment.titles.learn_more.guarantees.certified',
              content: 'new-shipment.paragraphs.learn_more.guarantees.certified',
              icon: 'certified'
            }
          },
          corridor: {
            fixed_price: {
              title: 'new-shipment.titles.learn_more.guarantees.fixed_price_corridor',
              content: 'new-shipment.paragraphs.learn_more.guarantees.fixed_price_lines',
              icon: 'budget'
            },
            cancellation: {
              title: 'new-shipment.titles.learn_more.guarantees.cancellation',
              content: 'new-shipment.paragraphs.learn_more.guarantees.cancellation',
              icon: 'cancel'
            },
            guarantee_corridor: {
              title: 'new-shipment.titles.learn_more.guarantees.guarantee_corridor',
              content: 'new-shipment.paragraphs.learn_more.guarantees.guarantee_corridor',
              icon: 'certified'
            }
          }
        }

        return guarantees[this.priceType]
      }
    }
  })
</script>

<style lang="scss" scoped>

  .new-shipment-quotation-learn-more-dialog-guarantees {
    &__item__content,
    &__title {
      color: $secondary-text;
    }

    &__title::after {
      content: '';
      position: absolute;
      bottom: -0.5rem;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $divider;
    }

    &__item {
      margin-bottom: 2.5rem;
    }

    @media screen and (min-width: $breakpoint-tablet) {
      &__item {
        position: relative;
        padding-left: 0.75rem;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          width: 2px;
          height: 100%;
          border-radius: 2px;
          background-color: $primary;
        }

        &:not(:last-child) {
          margin-right: 5rem;
        }
      }
    }
  }

</style>
